<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Create a Certification</h4>
                        <form @submit.prevent="createCertif()">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Registration code</label>
                                        <multiselect v-model="value" :select="onselection(value)" :options="registration_code" label="registration_code"  :multiple="false" placeholder="Search registration code"></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >name and Surname</label>
                                        <!-- <multiselect v-model="value" :options="options"  :multiple="false" placeholder="Search trainee name"></multiselect> -->
                                        <input  v-model="form.name_and_surname"
                                                type="text"
                                                placeholder="Trainee name"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <h4> Test scores</h4>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Quality assurance</label>
                                        <input v-model="form.quality_assurance"
                                        required
                                                type="number"
                                                placeholder="Quality assurance"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label> Overview of EID POCT</label>
                                    <input v-model="form.overview_of_eid_POCT"
                                    required
                                            type="number"
                                            placeholder="Overview of EID POCT"
                                            class="form-control"
                                    />

                                </div>
                                <div class="col-md-4">
                                    <label> Safety</label>
                                    <input v-model="form.safety"
                                    required
                                            type="number"
                                            placeholder="Safety"
                                            class="form-control"
                                    />

                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Specimen collection</label>
                                        <input v-model="form.specimen_collection"
                                        required
                                                type="number"
                                                placeholder="Specimen collection"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label> EID POC testing algorithm</label>
                                    <input v-model="form.eid_poc_testing_algorithm"
                                    required
                                            type="number"
                                            placeholder="EID POC testing algorithm"
                                            class="form-control"
                                    />

                                </div>
                                <div class="col-md-4">
                                    <label> Record keeping</label>
                                    <input v-model="form.record_keeping"
                                    required
                                            type="number"
                                            placeholder="Record keeping"
                                            class="form-control"
                                    />

                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>EQA/PT</label>
                                        <input v-model="form.EQA_PT"
                                        required
                                                type="number"
                                                placeholder="EQA/PT"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label> Professional ethics</label>
                                    <input v-model="form.professional_ethics"
                                    required
                                            type="number"
                                            placeholder="Professional ethics"
                                            class="form-control"
                                    />

                                </div>
                                <div class="col-md-4">
                                    <label> Inventory</label>
                                    <input v-model="form.inventory"
                                    required
                                            type="number"
                                            placeholder="Inventory"
                                            class="form-control"
                                    />

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Total Score Written</label>
                                        <input v-model="form.total_score_written"
                                        required
                                                type="number"
                                                placeholder="Total Score Written"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Total Practicals</label>
                                    <input v-model="form.total_practicals"
                                    required
                                            type="number"
                                            placeholder="Total Practicals"
                                            class="form-control"
                                    />

                                </div>
                                <div class="col-md-4">
                                    <label>% Performance written</label>
                                    <input v-model="form.percentage_performance_written"
                                    required
                                            type="number"
                                            placeholder="% Performance written"
                                            class="form-control"
                                    />

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Total % Performance</label>
                                        <input v-model="form.total_percentage_performance"
                                        required
                                                type="number"
                                                placeholder="Total % Performance"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary" type="submit">Create</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="lodader__content" v-if="loader">
            <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
        </div>
    </layout>
</template>

<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
    import { axios_get, axios_put} from "../../../../helpers/helper";

    export default {
        page: {
            title: "Create a Certification",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, Multiselect,PageHeader },
        data() {
            return {
                title: "Create a Certification",
                registration_code: [],
                loader:false,
                form: {
                    id: null,
                    name_and_surname: null,
                    registration_code: null,
                    quality_assurance: null,
                    overview_of_eid_POCT: null,
                    safety: null,
                    specimen_collection: null,
                    eid_poc_testing_algorithm: null,
                    record_keeping: null,
                    EQA_PT: null,
                    professional_ethics: null,
                    inventory: null,
                    total_practicals: null,
                    percentage_performance_written: null,
                    total_percentage_performance: null,
                    total_score_written: null,
                },
                items: [
                    {
                        text: "Dashboard",
                        href: "/"
                    },
                    {
                        text: "Create a Certification",
                        active: true
                    }
                ],
                value:null,
                user:null,
                options:[
                    "John doe",
                    "John doe",
                    "John doe",
                ],
            };

        },
        mounted() {
            this.user = JSON.parse(localStorage.getItem('user'))
            axios_get("/certif-mini", {}, (d)=>{
                this.registration_code = d.filter(e=>{
                    return e.region.country_id == this.user.country_id
                });
                console.log(d)
            }, (err)=>{
                console.log(err)
            }, (f)=>{
                console.log(f)
            })
        },
        validations: {

        },
        methods: {
            onselection(e){
                console.log(e)
                if(e){
                    this.form = e
                    //this.form.region = this.form.region.id
                    /* this.form.name_and_surname= e.name_and_surname,
                    this.form.registration_code= e.registration_code */
                }
                console.log(this.form)
            },

            createCertif(){
                this.loader=true
                this.form.region = this.form.region.id
                console.log(this.form)
                axios_put('/certif/'+this.form.id, this.form, (e)=>{
                        console.log('success', e)
                        this.form= {
                            id: null,
                            name_and_surname: null,
                            registration_code: null,
                            quality_assurance: null,
                            overview_of_eid_POCT: null,
                            safety: null,
                            specimen_collection: null,
                            eid_poc_testing_algorithm: null,
                            record_keeping: null,
                            EQA_PT: null,
                            professional_ethics: null,
                            inventory: null,
                            total_practicals: null,
                            percentage_performance_written: null,
                            total_percentage_performance: null,
                            total_score_written: null,
                        };
                        this.value = null
                    }, (err)=>{
                        console.log('error', err)
                    }, (f)=>{
                        console.log('final', f)
                        this.loader=false
                    }
                )
            }
        }
    };
</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>